import {instance} from "./Base";

export const sendAuthCodeRequest = async email => {
    return await instance.post("/auth", {email});
};

export const sendAuthRequest = async (email, otp) => {
    return await instance.post("/auth", {email, otp});
};

export const sendAutoAuthRequest = async () => {
    return await instance.post("/auto-auth");
};

export const sendLogoutRequest = async () => {
    return await instance.post("/logout");
};

export const sendTokenRefreshRequest = async () => {
    return await instance.post('/refresh-token');
};