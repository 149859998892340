import React from 'react';
import classes from "./ScrollProductCard.module.css";
import {Link} from "react-router-dom";

const ScrollProductCard = ({productId, productName, productPrice, productImg}) => {
    return (
        <article className={`${classes.productCard} col-1 row p-3 mx-2 text-center`}>
            <header className="col-12 p-0">
                <Link to={`/product/${productId}`}>
                    <img src={productImg} alt="" className="img-fluid" width="200px" height="200px" loading="lazy"/>
                </Link>
            </header>
            <span className="col-12 p-0 mt-2">
                <Link to={`/product/${productId}`} className={classes.productName}>{productName}</Link>
            </span>
            <footer className="col-12 p-0 d-flex justify-content-between align-items-end mt-3">
                <span className={classes.productPrice}>{`${productPrice} ₽`}</span>
                <Link className="btn" to={`../product/${productId}`}>КУПИТЬ</Link>
            </footer>
        </article>
    );
};

export default ScrollProductCard;