import React, {useContext, useEffect, useState} from 'react';
import classes from "./CategoriesBtn.module.css";
import {Link} from "react-router-dom";
import {getCategories} from "../../../../shared/services/CategoriesService";
import {CategoriesContext} from "../../../../app/contexts/CategoriesContext";

const CategoriesBtn = () => {
    const {categoriesContext, setCategoriesContext} = useContext(CategoriesContext);

    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getCategories(setCategoriesContext, setIsFetching).then();
        // eslint-disable-next-line
    }, [])

    return (
        <div className="col-12">
            <div className="dropdown">
                <button className={`${classes.category_btn} btn dropdown-toggle py-2 px-5 col-12`} type="button"
                        data-bs-toggle="dropdown">
                    Категории
                </button>
                <ul className="dropdown-menu col-12">
                    {
                        isFetching
                            ?
                            <li className="dropdown-item text-center p-0">
                                <div className="spinner-border">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                            </li>
                            :
                            categoriesContext.map(category =>
                                <li className="dropdown-item p-0" key={`category-${category.id}`}>
                                    <Link to={`/category/${category.id}`} className={classes.category_link}>
                                        {category.name}
                                    </Link>
                                </li>
                            )
                    }
                </ul>
            </div>
        </div>
    );
};

export default CategoriesBtn;