import React from 'react';
import {Link} from "react-router-dom";
import classes from "./LinkView.module.css";

const LinkView = () => {
    return (
        <ul className="navbar-nav">
            <li>
                <ul className="nav-item dropdown px-0">
                    <button className={`${classes.nav_link} nav-link dropdown-toggle`} data-bs-toggle="dropdown">
                        О компании
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <Link className={`${classes.dropdownLink} dropdown-item`} to="/about">
                                О нас
                            </Link>
                        </li>
                        <li>
                            <Link className={`${classes.dropdownLink} dropdown-item`} to="/contacts">
                                Контакты
                            </Link>
                        </li>
                        <li>
                            <Link className={`${classes.dropdownLink} dropdown-item`} to="/requisites">
                                Реквизиты
                            </Link>
                        </li>
                    </ul>
                </ul>
            </li>
        </ul>
    );
};

export default LinkView;