import {
    cancelOrderRequest,
    createOrderRequest,
    getOrdersRequest,
    payForOrderRequest,
    trackOrderRequest
} from "../api/Orders";
import {BaseService} from "./BaseService";

export const statuses = {
    CREATED: "СОЗДАН",
    READY_FOR_WORK: "ГОТОВ К РАБОТЕ",
    IN_WORK: "В РАБОТЕ",
    SENT: "ОТПРАВЛЕН",
    PICKUP: "ГОТОВ К ВЫДАЧЕ",
    COMPLETED: "ЗАВЕРШЕН",
    CANCELLED: "ОТМЕНЁН",
    REFUND: "ВОЗВРАТ"
};

export const createOrder = (order, setIsFetching) => {
    const reqFunc = () => createOrderRequest(order);
    const okFunc = data => data;
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const trackOrder = (orderId, setOrder, setIsFetching) => {
    const reqFunc = () => trackOrderRequest(orderId);
    const okFunc = data => {
        setOrder(data);
        return data;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const getOrders = (setOrders, page, setIsFetching) => {
    const reqFunc = () => getOrdersRequest(page);
    const okFunc = data => {
        setOrders(data.orders);
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const payForOrder = (orderId, setIsFetching) => {
    const reqFunc = () => payForOrderRequest(orderId);
    const okFunc = data => data;
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const cancelOrder = (orderId, setIsFetching) => {
    const reqFunc = () => cancelOrderRequest(orderId);
    const okFunc = () => true;
    return BaseService(reqFunc, okFunc, setIsFetching);
};