import React, {useContext, useEffect, useState} from 'react';
import AuthForm from "../../widgets/Auth/AuthForm/AuthForm";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../app/contexts/AuthContext";

const AuthPage = () => {
    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [authCode, setAuthCode] = useState("");

    const [isCodeSent, setIsCodeSent] = useState(false);

    useEffect(() => {
        if (authContext.isAuth) navigate("/profile");
        // eslint-disable-next-line
    }, [authContext]);

    return (
        <main className="container py-4 text-center">
            <h1 className="mb-3">Авторизация</h1>
            <div className="d-flex justify-content-center">
                <AuthForm authCode={authCode} setIsCodeSent={setIsCodeSent} isCodeSent={isCodeSent} email={email}
                          setAuthCode={setAuthCode} setEmail={setEmail} navigate={navigate}/>
            </div>
        </main>
    );
};

export default AuthPage;