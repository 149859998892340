import React from 'react';
import classes from "./SearchPagination.module.css";

const SearchPagination = ({page, navigate, query}) => {
    return (
        <button className={`${classes.paginationBtn} btn m-2`} key={`paginationBtn-${page}`}
                onClick={() => navigate(`?query=${query}&page=${page}`)}>
            {page}
        </button>
    );
};

export default SearchPagination;