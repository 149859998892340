import React, {useEffect, useState} from 'react';
import {getProduct} from "../../shared/services/ProductsService";
import {Link, useNavigate, useParams} from "react-router-dom";
import classes from "./ProductPage.module.css";
import Carousel from "../../widgets/Carousel/Carousel";
import ProductDetails from "../../widgets/ProductDetails/ProductDetails";
import BuyForm from "../../widgets/BuyForm/BuyForm";

const ProductPage = () => {
    const {productId} = useParams();
    const numProductId = Number(productId);
    const navigate = useNavigate();

    const [productInfo, setProductInfo] = useState({});
    const [cart, setCart] = useState([]);
    const [productAmount, setProductAmount] = useState(1);

    const [isFetching, setIsFetching] = useState(true);
    const [inCart, setInCart] = useState(false);

    useEffect(() => {
        getProduct(productId, setProductInfo, setIsFetching).then(
            result => {
                if (result) {
                    const cartInfo = localStorage.getItem('cart');
                    if (cartInfo) {
                        const cartObj = JSON.parse(cartInfo);
                        setCart(cartObj);

                        const cartProductIndex = cartObj.findIndex(obj => obj.id === numProductId);
                        if (cartProductIndex !== -1) setInCart(true);
                    } else localStorage.setItem('cart', JSON.stringify([]));
                } else navigate('/');
            }
        );
        // eslint-disable-next-line
    }, [productId]);

    return (
        <main className="container py-4">
            {
                isFetching ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    <div className="row">
                        <section className="col-12 col-lg-6">
                            <Carousel productInfo={productInfo}/>
                        </section>
                        <section className="col-12 col-lg-6 my-3 my-lg-0">
                            <header className={classes.productHeader}>
                                <h1 className={classes.productName}>{productInfo.name}</h1>
                                <div className="my-4">
                                    <div className={`${classes.productPrice} my-3`}>{`${productInfo.price} ₽`}</div>
                                    <BuyForm setCart={setCart} cart={cart} productAmount={productAmount}
                                             numProductId={numProductId} setProductAmount={setProductAmount}
                                             inCart={inCart} setInCart={setInCart}/>
                                </div>
                            </header>
                            <section className="mt-3">
                                <div>
                                    <b>Артикул:</b>&nbsp;
                                    <span className={classes.productArticle}>{productInfo.article}</span>
                                </div>
                                <div>
                                    <b>Категории:</b>&nbsp;
                                    {
                                        productInfo.category.map(
                                            category => <Link to={`../category/${category.id}`}
                                                              key={`categoryLink-${category.id}`}
                                                              className={classes.categoryLink}>{category.name}</Link>
                                        ).reduce((prev, curr) => [prev, ', ', curr])
                                    }
                                </div>
                            </section>
                        </section>
                        <section className={`${classes.productDetails} col-12 mt-0 mt-lg-4 py-3`}>
                            <section className="col-12 col-lg-10 mb-4">
                                <h2 className={classes.productDescriptionTitle}>Описание</h2>
                                <p className={`${classes.productDescription} py-2`}>{productInfo.description}</p>
                            </section>
                            <section className="col-12 col-lg-5">
                                <ProductDetails productId={productId} productInfo={productInfo}/>
                            </section>
                        </section>
                        {window.YandexRotorSettings.isLoaded = true}
                    </div>
            }
        </main>
    );
};

export default ProductPage;