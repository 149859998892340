import React from 'react';
import {useRouteError} from "react-router-dom";

const ErrorPage = () => {
    const error = useRouteError();

    console.error(error);

    return (
        <main className="container my-4">
            <h1 className="d-flex justify-content-center fw-semibold">{`${error.status} ${error.statusText}`}</h1>
        </main>
    );
};

export default ErrorPage;