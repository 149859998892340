import React from "react";
import Pagination from "../../entities/Pagination/Pagination";
import SearchPagination from "../../entities/SearchPagination/SearchPagination";

export const getPagination = (pages, navigate, setPaginationBtn) => {
    let newPaginationBtn = [];
    for (let i = 1; i <= pages; i++) {
        newPaginationBtn.push(
            <Pagination page={i} navigate={navigate} key={`paginationBtn-${i}`}/>
        );
    }
    setPaginationBtn(newPaginationBtn);
};

export const getSearchPagination = (pages, navigate, query, setPaginationBtn) => {
    let newPaginationBtn = [];
    for (let i = 1; i <= pages; i++) {
        newPaginationBtn.push(
            <SearchPagination page={i} navigate={navigate} query={query} key={`paginationBtn-${i}`}/>
        );
    }
    setPaginationBtn(newPaginationBtn);
};