import React, {useContext} from 'react';
import classes from "./CheckoutSidebar.module.css";
import CheckoutProductItem from "../../../entities/CheckoutProductItem/CheckoutProductItem";
import {createOrderCallback} from "../../../features/Checkout/Checkout";
import {AlertContext} from "../../../app/contexts/AlertContext";

const CheckoutSidebar = ({
                             cartProducts,
                             cart,
                             isPriceFetching,
                             cartPrice,
                             deliveryType,
                             customerData,
                             paymentType,
                             setIsBtnFetching,
                             navigate,
                             isBtnFetching
                         }) => {
    const {alertContext, setAlertContext} = useContext(AlertContext);

    return (
        <div className={`${classes.checkoutSidebar} p-4`}>
            <h3 className="mb-4">ВАШ ЗАКАЗ</h3>
            <div className="mb-4">
                {
                    cartProducts.map(
                        product =>
                            <CheckoutProductItem product={product} cart={cart} key={`productItem-${product.id}`}/>
                    )
                }
            </div>
            <div className="d-flex justify-content-between mb-4">
                <span className={classes.totalText}>Итого</span>
                <span className={classes.totalPrice}>
                    {
                        isPriceFetching ?
                            <div className="spinner-border spinner-border-sm mx-2">
                                <span className="visually-hidden">Загрузка...</span>
                            </div>
                            : `${cartPrice} ₽`
                    }
                </span>
            </div>
            <div className="d-flex justify-content-center">
                {
                    isPriceFetching ?
                        <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                        :
                        <button className="col-12 btn"
                                onClick={
                                    () =>
                                        createOrderCallback(deliveryType, customerData, paymentType, cart,
                                            setIsBtnFetching, alertContext, setAlertContext, navigate)
                                }>
                            Оплатить
                            {
                                isBtnFetching ?
                                    <div className="spinner-border spinner-border-sm ms-2">
                                        <span className="visually-hidden">Загрузка...</span>
                                    </div>
                                    : null
                            }
                        </button>
                }
            </div>
        </div>
    );
};

export default CheckoutSidebar;