import React from 'react';
import DeliveryInputs from "./DeliveryInputs/DeliveryInputs";
import PickupInputs from "./PickupInputs/PickupInputs";

const CheckoutDetailsForm = ({
                                 customerData,
                                 setCustomerData,
                                 deliveryType,
                                 setDeliveryType,
                                 paymentType,
                                 setPaymentType
                             }) => {
    return (
        <form className="row">
            <div className="col-12 mb-3">
                <label htmlFor="deliveryType" className="form-label">Тип получения</label>
                <select className="form-select" id="deliveryType" value={deliveryType}
                        onChange={
                            event => setDeliveryType(event.target.value)
                        }>
                    <option disabled value="">Выберите тип получения</option>
                    <option value="PICKUP">Самовывоз</option>
                    <option value="DELIVERY">Доставка</option>
                </select>
            </div>
            {
                deliveryType === "DELIVERY" ?
                    <DeliveryInputs customerData={customerData} setCustomerData={setCustomerData}/>
                    :
                    deliveryType === "PICKUP" ?
                        <PickupInputs customerData={customerData} setCustomerData={setCustomerData}
                                      paymentType={paymentType} setPaymentType={setPaymentType}/>
                        : null
            }
        </form>
    );
};

export default CheckoutDetailsForm;