import React, {useContext} from 'react';
import SearchForm from "./SearchForm/SearchForm";
import CategoriesBtn from "./CategoriesBtn/CategoriesBtn";
import {Link} from "react-router-dom";
import classes from "./Navbar.module.css";
import LinkView from "./LinkView/LinkView";
import {AuthContext} from "../../../app/contexts/AuthContext";

const Navbar = () => {
    const {authContext} = useContext(AuthContext);

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container my-2 my-lg-0">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false">
                    <i className="navbar-toggler-icon"></i>
                </button>
                <div className="collapse navbar-collapse my-2 my-lg-0" id="navbarToggler">
                    <div className="container my-0 my-lg-3">
                        <div className="align-items-center row">
                            <div className="col-lg-auto d-flex justify-content-end my-2 my-lg-0 px-0">
                                <CategoriesBtn/>
                            </div>
                            <div className="row col-12 col-lg ms-lg-2">
                                <LinkView/>
                            </div>
                            <div className="col col-lg-4 d-flex justify-content-end my-2 my-lg-0 px-0">
                                <SearchForm/>
                            </div>
                            <div className="col-2 col-lg-auto text-center ms-0 ms-lg-3 p-0">
                                <Link to="/cart"><i className={`${classes.cartIcon} bi-bag`}></i></Link>
                            </div>
                            <div className="col-2 col-lg-auto text-center ms-0 ms-lg-3 p-0">
                                <Link to={authContext.isAuth ? "/profile" : "/auth"}>
                                    <i className={`${classes.userIcon} bi-person`}></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;