import React, {useEffect, useState} from 'react';
import classes from "./CartProductItem.module.css";
import {Link} from "react-router-dom";
import {changeProductCountCallback, removeProductCallback} from "../../features/Cart/Cart";

const CartProductItem = ({
                             index,
                             product,
                             cartProducts,
                             setCartProducts,
                             cart,
                             setCart,
                             setCartPrice,
                             setIsPriceFetching,
                             setWrongProductsExists,
                             setTotalInfo
                         }) => {
    const [productAmount, setProductAmount] = useState(0);

    useEffect(() => {
        cart.forEach(
            cartProduct =>
                (cartProduct.id === product.id) ?
                    setProductAmount(cartProduct.amount)
                    : null
        );
        // eslint-disable-next-line
    }, [cart]);

    return (
        <article className={`${classes.cartProductItem} row align-items-center py-2`}>
            <div className="col-4 col-lg-2">
                <Link to={`../product/${product.id}`}>
                    <img className={classes.productImg} src={product.image[0].href} alt={product.name} loading="lazy"/>
                </Link>
            </div>
            <div className="col-8 col-lg-10 row align-items-center mx-0 px-0">
                <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                    <Link to={`../product/${product.id}`} className={classes.productLink}>
                        {product.name}
                        {
                            !product.isActive ?
                                <span className={`${classes.inactive} ms-2`}>НЕТ В НАЛИЧИИ!</span>
                                : null
                        }
                    </Link>
                </div>
                <div className="col-5 col-lg text-start text-lg-center my-2 my-lg-0">{`${product.price} ₽`}</div>
                <div className="col-5 col-lg my-2 my-lg-0 mx-0 px-0">
                    <input type="number" className="form-control" value={productAmount}
                           onChange={
                               event => changeProductCountCallback(event.target.value, product, cart, setCart,
                                   setTotalInfo, setCartPrice, setIsPriceFetching, setWrongProductsExists)
                           }/>
                </div>
                <div className="col-1 d-block d-lg-none">
                    <button className="btn btn-close" onClick={
                        () => removeProductCallback(index, product, setTotalInfo, cart, setCart, cartProducts,
                            setCartProducts, setCartPrice, setIsPriceFetching, setWrongProductsExists)
                    }></button>
                </div>
                <div className="col-lg-2 text-end d-none d-lg-block">
                    {`${(product.price * productAmount).toFixed(2)} ₽`}
                </div>
                <div className="col-lg-auto text-end d-none d-lg-block">
                    <button className="btn-close" onClick={
                        () => removeProductCallback(index, product, setTotalInfo, cart, setCart, cartProducts,
                            setCartProducts, setCartPrice, setIsPriceFetching, setWrongProductsExists)
                    }></button>
                </div>
            </div>
        </article>
    );
};

export default CartProductItem;