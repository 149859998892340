import React from 'react';
import classes from "./AboutPage.module.css";

const AboutPage = () => {
    return (
        <main className="container py-4">
            <h1 className={classes.pageTitle}>О нас</h1>
            <section>
                <p className={classes.pageText}>
                    Наш магазин предлагает разнообразие размеров, материалов и дизайнов для удовлетворения потребностей
                    бизнеса любого масштаба. Гарантируем надежность и быструю доставку по всей стране. Сделайте свой
                    выбор умно – выбирайте оптовые мешки и пакеты у нас!
                </p>
            </section>
            {window.YandexRotorSettings.isLoaded = true}
        </main>
    );
};

export default AboutPage;