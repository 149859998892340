import React from 'react';

const TotalInfo = ({totalInfo}) => {
    return (
        <>
            <div className="col-2 fs-5"><b>Итого</b></div>
            <div
                className="col-10 justify-content-end row align-items-center mx-auto mx-lg-0 px-1">
                <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 mx-lg-3 px-0 text-end">
                    {`${totalInfo.totalWeight.toFixed(2)} кг`}
                </div>
                <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 px-0 text-end">
                    {`${totalInfo.totalAmount} шт`}
                </div>
                <div className="col-3 text-end">
                    {`${totalInfo.totalPrice.toFixed(2)} ₽`}
                </div>
            </div>
        </>
    );
};

export default TotalInfo;