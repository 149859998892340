import React from 'react';
import {Link} from "react-router-dom";
import classes from "./ProductItem.module.css";

const ProductItem = ({product}) => {
    return (
        <div className={`${classes.productItem} row align-items-center py-2 py-lg-1`}>
            <div className="col-2 d-none d-lg-block">
                <Link to={`../product/${product.id}`}>
                    <img src={product.image} alt={product.name} className={`${classes.productImg} img-fluid`}
                         loading="lazy"/>
                </Link>
            </div>
            <div className="col-12 col-lg-10 row align-items-center mx-auto mx-lg-0 px-1">
                <div className="col col-lg d-flex justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                    <Link to={`../product/${product.id}`} className={classes.productLink}>
                        {product.name}
                    </Link>
                </div>
                <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 mx-lg-3 px-0 text-end">
                    {`${product.totalWeight.toFixed(2)} кг`}
                </div>
                <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 px-0 text-end">
                    {`${product.amount} шт`}
                </div>
                <div className="col-3 text-end">
                    {`${product.totalPrice.toFixed(2)} ₽`}
                </div>
            </div>
        </div>
    );
};

export default ProductItem;