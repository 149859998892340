import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import classes from "./TrackOrderPage.module.css";
import ProductItem from "../../widgets/TrackOrder/ProductItem/ProductItem";
import {trackOrder} from "../../shared/services/OrdersService";
import CustomerData from "../../widgets/TrackOrder/CustomerData/CustomerData";
import {cancelOrderCallback, payForOrderCallback} from "../../features/Order/Order";
import {AlertContext} from "../../app/contexts/AlertContext";

const TrackOrderPage = () => {
    const {orderId} = useParams();
    const navigate = useNavigate();
    const {alertContext, setAlertContext} = useContext(AlertContext);

    const [order, setOrder] = useState({});
    const [totalInfo, setTotalInfo] = useState({
        totalWeight: 0, totalAmount: 0, totalPrice: 0
    });

    const [isFetching, setIsFetching] = useState(true);
    const [isPayBtnFetching, setIsPayBtnFetching] = useState(false);
    const [isCancelBtnFetching, setIsCancelBtnFetching] = useState(false);

    useEffect(() => {
        trackOrder(orderId, setOrder, setIsFetching).then(
            data => {
                if (data) {
                    let totalWeight = 0;
                    let totalAmount = 0;
                    let totalPrice = 0;
                    data.products.forEach(
                        product => {
                            totalWeight += product.totalWeight;
                            totalAmount += product.amount;
                            totalPrice += product.totalPrice;
                        }
                    )
                    setTotalInfo({totalWeight, totalAmount, totalPrice})
                } else navigate("/auth");
            }
        );
        // eslint-disable-next-line
    }, []);

    window.document.title = "Мешки-Пакеты - Отслеживание заказа";

    return (
        <main className="container py-4">
            {
                isFetching ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    <div className="row d-flex justify-content-between">
                        <div className="col-12 col-lg-6">
                            <h1 className="mb-3">
                                {`Отслеживание заказа #${orderId}`}
                            </h1>
                            <div className={`${classes.emailNotify} mb-3`}>
                                <span>Информация по заказу отправлена вам на почту</span>
                            </div>
                            {
                                order.products.map(
                                    product =>
                                        <ProductItem product={product} key={`productItem-${product.id}`}/>
                                )
                            }
                            <div className="row justify-content-between py-2 py-lg-3 px-1 align-items-center">
                                <div className="col-2 fs-5">
                                    <b>Итого</b>
                                </div>
                                <div
                                    className="col-10 justify-content-end row align-items-center mx-auto mx-lg-0 px-1">
                                    <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 mx-lg-3 px-0 text-end">
                                        {`${totalInfo.totalWeight.toFixed(2)} кг`}
                                    </div>
                                    <div className="col-2 col-lg-auto my-2 my-lg-0 mx-0 px-0 text-end">
                                        {`${totalInfo.totalAmount} шт`}
                                    </div>
                                    <div className="col-3 text-end">
                                        {`${totalInfo.totalPrice.toFixed(2)} ₽`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 ms-lg-5">
                            <CustomerData customerData={order.customerData} pin={order.pinCode} status={order.status}/>
                            <div className="mt-3 d-flex justify-content-center">
                                {
                                    (order.status === "CREATED") ?
                                        <button className="col-5 btn mx-3"
                                                onClick={
                                                    () => payForOrderCallback(orderId, setIsPayBtnFetching, navigate,
                                                        alertContext, setAlertContext)
                                                }>
                                            <span>Оплатить</span>
                                            {
                                                isPayBtnFetching ?
                                                    <div className="spinner-border spinner-border-sm ms-2">
                                                        <span className="visually-hidden">Загрузка...</span>
                                                    </div>
                                                    : null
                                            }
                                        </button>
                                        : null
                                }
                                {
                                    (order.status === "CREATED" || order.status === "READY_FOR_WORK") ?
                                        <button className={`${classes.cancelBtn} col-5 btn mx-3`}
                                                onClick={
                                                    () => cancelOrderCallback(orderId, setIsCancelBtnFetching, navigate,
                                                        alertContext, setAlertContext)
                                                }>
                                            <span>Отменить</span>
                                            {
                                                isCancelBtnFetching ?
                                                    <div className="spinner-border spinner-border-sm ms-2">
                                                        <span className="visually-hidden">Загрузка...</span>
                                                    </div>
                                                    : null
                                            }
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
            }
        </main>
    );
};

export default TrackOrderPage;