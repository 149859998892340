import React, {useContext, useState} from 'react';
import {authCallback, sendAuthCodeCallback} from "../../../features/Auth/Auth";
import {AlertContext} from "../../../app/contexts/AlertContext";
import {AuthContext} from "../../../app/contexts/AuthContext";

const AuthForm = ({email, setEmail, authCode, setAuthCode, isCodeSent, setIsCodeSent, navigate}) => {
    const {setAuthContext} = useContext(AuthContext);
    const {alertContext, setAlertContext} = useContext(AlertContext);

    const [isFetching, setIsFetching] = useState(false);

    return (
        <form className="col-12 col-lg-4 my-2"
              onSubmit={
                  event => {
                      event.preventDefault();
                      if (isCodeSent) authCallback(email, authCode, setAuthContext, setIsFetching, navigate, alertContext,
                          setAlertContext);
                      else sendAuthCodeCallback(email, setIsFetching, setIsCodeSent, alertContext, setAlertContext);
                  }
              }>
            <div className="input-group">
                <label htmlFor="emailInput" className="input-group-text">Email</label>
                <input type="email" className="form-control" id="emailInput" value={email} disabled={isCodeSent}
                       onChange={event => setEmail(event.target.value)}/>
            </div>
            {
                isCodeSent ?
                    <div className="input-group">
                        <label htmlFor="codeInput" className="input-group-text">Код</label>
                        <input type="text" className="form-control" id="codeInput" value={authCode} autoFocus
                               onChange={event => setAuthCode(event.target.value)}/>
                    </div>
                    : null
            }
            {
                isFetching ?
                    <div className="spinner-border mt-3"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    <button className="btn mt-3" type="submit">
                        {isCodeSent ? "Авторизоваться" : "Отправить код"}
                    </button>
            }
        </form>
    );
};

export default AuthForm;