import {
    showCancelOrderErrorToast,
    showCancelOrderSuccessfulToast,
    showCreateOrderMessageToast
} from "../../shared/libs/Toasts";
import {cancelOrder, payForOrder} from "../../shared/services/OrdersService";

export const payForOrderCallback = (orderId, setIsFetching, navigate, alertContext, setAlertContext) => {
    payForOrder(orderId, setIsFetching).then(
        data => {
            if (data) {
                if (data.confirmationUrl) window.location.href = data.confirmationUrl;
                else if (data.message) showCreateOrderMessageToast(data.message, alertContext, setAlertContext);
                else navigate("/");
            }
        }
    );
};

export const cancelOrderCallback = (orderId, setIsFetching, navigate, alertContext, setAlertContext) => {
    cancelOrder(orderId, setIsFetching).then(
        data => {
            if (data) {
                navigate("/profile");
                showCancelOrderSuccessfulToast(alertContext, setAlertContext);
            } else showCancelOrderErrorToast(alertContext, setAlertContext);
        }
    );
};