import React, {useEffect, useState} from 'react';
import classes from "./Mainpage.module.css";
import ScrollProductCard from "../../entities/ScrollProductCard/ScrollProductCard";
import ProductCard from "../../entities/ProductCard/ProductCard";
import {randomProducts} from "../../shared/services/ProductsService";
import AutoCarousel from "../../widgets/AutoCarousel/AutoCarousel";

const MainPage = () => {
    const [products, setProducts] = useState([]);

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        randomProducts(setProducts, setIsFetching).then();
        // eslint-disable-next-line
    }, []);

    window.document.title = "Мешки-Пакеты - оптовый поставщик упаковочных материалов";

    return (
        <main className="container py-4">
            {
                isFetching ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    (products.length !== 0) ?
                        <>
                            <section className="mb-4 d-flex justify-content-center">
                                <AutoCarousel products={products}/>
                            </section>
                            <section className={`${classes.wrapper} py-3 px-4`}>
                                <div className={`${classes.inner} row`}>
                                    {
                                        products.slice(5, 13).map(
                                            product =>
                                                <ScrollProductCard key={`scrollProductCard-${product.id}`}
                                                                   productId={product.id} productName={product.name}
                                                                   productPrice={product.price}
                                                                   productImg={product.image[0].href}/>
                                        )
                                    }
                                </div>
                            </section>
                            <section className="row row-cols-4 mt-4 justify-content-center">
                                {
                                    products.slice(13, 21).map(
                                        product =>
                                            <ProductCard productId={product.id} productName={product.name}
                                                         productPrice={product.price} productImg={product.image[0].href}
                                                         key={`productCard-${product.id}`}/>
                                    )
                                }
                            </section>
                            {window.YandexRotorSettings.isLoaded = true}
                        </>
                        :
                        <>
                            <h1>Товаров не найдено!</h1>
                            {window.YandexRotorSettings.isLoaded = true}
                        </>
            }
        </main>
    );
};

export default MainPage;