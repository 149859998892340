import {instance} from "./Base";

export const createOrderRequest = async order => {
    return await instance.post("/create-order", {...order});
};

export const trackOrderRequest = async orderId => {
    return await instance.get("/order", {
        params: {orderId}
    });
};

export const getOrdersRequest = async page => {
    return await instance.get("/orders", {
        page: page
    });
};

export const payForOrderRequest = async orderId => {
    return await instance.post("/pay-for-order", {orderId});
};

export const cancelOrderRequest = async orderId => {
    return await instance.post("/cancel-order", {orderId});
};