import {BaseService} from "./BaseService";
import {
    getCartPriceRequest,
    getCartProductsRequest,
    getProductRequest,
    getProductsByCategoryIdRequest,
    randomProductsRequest,
    searchRequest
} from "../api/Products";

export const getProductByCategoryId = (categoryId, page, setProducts, setIsFetching) => {
    const reqFunc = () => getProductsByCategoryIdRequest(categoryId, page);
    const okFunc = data => {
        setProducts(data.products);
        return data;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const getProduct = (productId, setProductInfo, setIsFetching) => {
    const reqFunc = () => getProductRequest(productId);
    const okFunc = data => {
        setProductInfo(data);
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const getCartProducts = (productsId, setCartProducts, setIsFetching) => {
    const reqFunc = () => getCartProductsRequest(productsId);
    const okFunc = data => {
        setCartProducts(data);
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const getCartPrice = (cart, setCartPrice, setIsFetching) => {
    const reqFunc = () => getCartPriceRequest(cart);
    const okFunc = data => {
        setCartPrice(data.totalPrice);
        return data;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const search = (query, page, setProducts, setIsFetching) => {
    const reqFunc = () => searchRequest(query, page);
    const okFunc = data => {
        setProducts(data.products);
        return data;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const randomProducts = (setProducts, setIsFetching) => {
    const reqFunc = () => randomProductsRequest(21);
    const okFunc = data => setProducts(data);
    return BaseService(reqFunc, okFunc, setIsFetching);
};