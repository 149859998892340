import React from 'react';

const PickupInputs = ({customerData, setCustomerData, paymentType, setPaymentType}) => {
    return (
        <>
            <div className="col-12 mb-3">
                <label htmlFor="paymentType" className="form-label">Тип получения</label>
                <select className="form-select" id="paymentType" value={paymentType}
                        onChange={
                            event => setPaymentType(event.target.value)
                        }>
                    <option disabled value="">Выберите вариант оплаты</option>
                    <option value="ONLINE">Онлайн</option>
                    <option value="CASH">Наличные</option>
                </select>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="lastName" className="form-label">Фамилия</label>
                <input type="text" className="form-control" id="lastName" value={customerData.lastName}
                       onChange={
                           event => setCustomerData({...customerData, lastName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="firstName" className="form-label">Имя</label>
                <input type="text" className="form-control" id="firstName"
                       value={customerData.firstName}
                       onChange={
                           event => setCustomerData({...customerData, firstName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="middleName" className="form-label">Отчество</label>
                <input type="text" className="form-control" id="middleName"
                       value={customerData.middleName}
                       onChange={
                           event => setCustomerData({...customerData, middleName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="phone" className="form-label">Телефон</label>
                <input type="tel" className="form-control" id="phone" maxLength="11"
                       placeholder="В формате 79998887766" value={customerData.phone}
                       onChange={
                           event => {
                               const changedValue = event.target.value;
                               const isValidNumber = !isNaN(Number(changedValue)) &&
                                   (changedValue[0] === "7" || changedValue === "");
                               if (isValidNumber) setCustomerData({
                                   ...customerData,
                                   phone: changedValue.replaceAll('.', '')
                               });
                           }
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="email" className="form-label">Email (на него придет информация о заказе)</label>
                <input type="email" className="form-control" id="email" value={customerData.email}
                       onChange={
                           event => setCustomerData({...customerData, email: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="details" className="form-label">Комментарий к заказу (необязательно)</label>
                <textarea className="form-control" id="details" value={customerData.details}
                          onChange={
                              event => setCustomerData({...customerData, details: event.target.value})
                          }/>
            </div>
        </>
    );
};

export default PickupInputs;