import React, {useEffect, useState} from 'react';

const CheckoutProductItem = ({product, cart}) => {
    const [productAmount, setProductAmount] = useState(0);

    useEffect(() => {
        const cartProduct = cart.find(cartProduct => cartProduct.id === product.id);
        setProductAmount(cartProduct.amount);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="row my-2 mx-0 px-1 py-3 border border-2 align-items-center">
            <div className="col-8">{product.name}</div>
            <div className="col text-end">
                {`${(product.price * productAmount).toFixed(2)} ₽`}
            </div>
        </div>
    );
};

export default CheckoutProductItem;