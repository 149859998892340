import React, {useEffect, useState} from 'react';
import {getCartPrice, getCartProducts} from "../../shared/services/ProductsService";
import CartProductItem from "../../entities/CartProductItem/CartProductItem";
import {updateTotalInfo} from "../../features/Cart/Cart";
import TotalInfo from "../../widgets/Cart/TotalInfo/TotalInfo";
import CartSidebar from "../../widgets/Cart/CartSidebar/CartSidebar";

const CartPage = () => {
    const [cart, setCart] = useState([]);
    const [cartProducts, setCartProducts] = useState([]);
    const [cartPrice, setCartPrice] = useState(NaN);
    const [totalInfo, setTotalInfo] = useState({
        totalWeight: 0,
        totalAmount: 0,
        totalPrice: 0
    });

    const [isFetching, setIsFetching] = useState(true);
    const [isPriceFetching, setIsPriceFetching] = useState(true);

    const [wrongProductsExists, setWrongProductsExists] = useState(false);

    useEffect(() => {
        const cartInfo = localStorage.getItem('cart');
        if (cartInfo) {
            const cartObj = JSON.parse(cartInfo);
            setCart(cartObj);

            const products = cartObj.map(obj => obj.id);
            getCartProducts(products, setCartProducts, setIsFetching).then(
                data =>
                    data ?
                        getCartPrice(cartObj, setCartPrice, setIsPriceFetching).then(
                            result =>
                                (result.wrongProducts.length !== 0) ?
                                    setWrongProductsExists(true)
                                    : null
                        )
                        : null
            );
            updateTotalInfo(products, cartObj, setTotalInfo);
        } else {
            localStorage.setItem("cart", "[]");
            setIsFetching(false);
        }
        // eslint-disable-next-line
    }, []);

    window.document.title = "Мешки-Пакеты - Корзина";

    return (
        <main className="container py-4">
            <header>
                <h1 className="mb-3">Корзина</h1>
            </header>
            {
                isFetching ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    cartProducts.length ?
                        <div className="row d-flex justify-content-between">
                            <div className="col-12 col-lg-8">
                                {
                                    cartProducts.map(
                                        (product, index) =>
                                            <CartProductItem index={index} product={product} cartProducts={cartProducts}
                                                             setCartProducts={setCartProducts} cart={cart}
                                                             setCart={setCart} setCartPrice={setCartPrice}
                                                             setIsPriceFetching={setIsPriceFetching}
                                                             setWrongProductsExists={setWrongProductsExists}
                                                             setTotalInfo={setTotalInfo}
                                                             key={`cartProductItem-${product.id}`}/>
                                    )
                                }
                                <div className="row justify-content-between py-2 py-lg-3 px-1 align-items-center">
                                    <TotalInfo totalInfo={totalInfo}/>
                                </div>
                            </div>
                            <div className="col-12 col-lg mt-3 ms-lg-5 d-flex justify-content-center d-lg-block">
                                <CartSidebar isPriceFetching={isPriceFetching} cartPrice={cartPrice}
                                             wrongProductsExists={wrongProductsExists}/>
                            </div>
                        </div>
                        :
                        <div>
                            <h1>Товары отсутствуют!</h1>
                        </div>
            }
        </main>
    );
};

export default CartPage;