import React, {useEffect, useRef, useState} from 'react';
import './styles/reset.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './styles/index.css';
import Header from "../widgets/Header/Header";
import Footer from "../widgets/Footer/Footer";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {AlertContext} from "./contexts/AlertContext";
import {CategoriesContext} from "./contexts/CategoriesContext";
import Toast from "../entities/Toast/Toast";
import MainPage from "../pages/MainPage/MainPage";
import CategoryPage from "../pages/CategoryPage/CategoryPage";
import ProductPage from "../pages/ProductPage/ProductPage";
import SearchPage from "../pages/SearchPage/SearchPage";
import CartPage from "../pages/CartPage/CartPage";
import CheckoutPage from "../pages/CheckoutPage/CheckoutPage";
import TrackOrderPage from "../pages/TrackOrderPage/TrackOrderPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ContactsPage from "../pages/ContactsPage/ContactsPage";
import AboutPage from "../pages/AboutPage/AboutPage";
import RequisitesPage from "../pages/RequisitesPage/RequisitesPage";
import AuthPage from "../pages/AuthPage/AuthPage";
import {v4 as uuidv4} from "uuid";
import {AuthContext} from "./contexts/AuthContext";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import {autoAuthCallback} from "../features/Auth/Auth";

const router = createBrowserRouter([
    {
        element: <Layout/>,
        errorElement: (
            <Layout>
                <ErrorPage/>
            </Layout>
        ),
        children: [
            {index: true, Component: MainPage},
            {path: "/category/:categoryId", Component: CategoryPage},
            {path: "/product/:productId", Component: ProductPage},
            {path: "/search-products", Component: SearchPage},
            {path: "/cart", Component: CartPage},
            {path: "/checkout", Component: CheckoutPage},
            {path: "/track-order/:orderId", Component: TrackOrderPage},
            {path: "/contacts", Component: ContactsPage},
            {path: "/about", Component: AboutPage},
            {path: "/requisites", Component: RequisitesPage},
            {path: "/auth", Component: AuthPage},
            {path: "/profile", Component: ProfilePage},
        ],
    },
]);

function App() {
    return <RouterProvider router={router}/>;
}

function Layout({children}) {
    const toast = useRef();

    const [authContext, setAuthContext] = useState({email: "", isAuth: false});
    const [alertContext, setAlertContext] = useState({alert_text: "", toast: toast});
    const [categoriesContext, setCategoriesContext] = useState([]);

    const [isFetching, setIsFetching] = useState(false);

    useEffect(
        () => {
            if (!localStorage.getItem("deviceId")) {
                localStorage.setItem("deviceId", uuidv4());
            }
            if (!localStorage.getItem("isAuth")) {
                localStorage.setItem("isAuth", 'false');
            }
            if (localStorage.getItem("isAuth") === 'true') {
                autoAuthCallback(setAuthContext, setIsFetching);
            }
            // eslint-disable-next-line
        }, []
    );

    return (
        <AuthContext.Provider value={{authContext, setAuthContext}}>
            <AlertContext.Provider value={{alertContext, setAlertContext}}>
                <CategoriesContext.Provider value={{categoriesContext, setCategoriesContext}}>
                    {
                        isFetching ?
                            <div className="loader d-flex justify-content-center align-items-center">
                                <div className="spinner-border">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                            </div>
                            :
                            <>
                                <Header/>

                                <Toast alertContext={alertContext} setAlertContext={setAlertContext}/>

                                {children ?? <Outlet/>}

                                <Footer/>
                            </>
                    }
                </CategoriesContext.Provider>
            </AlertContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;