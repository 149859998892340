import React from 'react';
import {Link} from "react-router-dom";
import {addProductToCart} from "../../features/Cart/Cart";

const BuyForm = ({inCart, productAmount, setProductAmount, cart, numProductId, setCart, setInCart}) => {
    return (
        <div className="col-4">
            <div className="input-group">
                {
                    inCart ?
                        <Link to="../cart" className="btn">В КОРЗИНЕ</Link>
                        :
                        <>
                            <input type="number" className="form-control" value={productAmount}
                                   onChange={
                                       event => {
                                           const changedNum = Number(event.target.value);
                                           if (1 <= changedNum && changedNum <= 9999) setProductAmount(changedNum);
                                       }
                                   }/>
                            <button className="btn"
                                    onClick={
                                        () => addProductToCart(cart, numProductId, productAmount, setCart, setInCart)
                                    }>
                                В КОРЗИНУ
                            </button>
                        </>
                }
            </div>
        </div>
    );
};

export default BuyForm;