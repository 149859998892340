import React from 'react';
import classes from "./ContactsPage.module.css";
import {Link} from "react-router-dom";

const ContactsPage = () => {
    return (
        <main className="container py-4">
            <h1 className="mb-3">Контакты 😎</h1>
            <section className={classes.contacts}>
                <div className="col-12 mt-2">
                    <Link to="tel:+79032381725">
                        <i className="bi-telephone me-2"></i>&nbsp;
                        <span>+7 903 238 1725</span>
                    </Link>
                </div>
                <div className="col-12 mt-2">
                    <Link to="mailto:support@meshki-pakety.ru">
                        <i className="bi-chat-left-text me-2"></i>&nbsp;
                        <span>support@meshki-pakety.ru</span>
                    </Link>
                </div>
                <div className="col-12 mt-2">
                    <i className="bi-compass me-2"></i>&nbsp;
                    <address className="d-inline-block m-0">г. Москва, ул. 26 Бакинских Комиссаров, 8с2</address>
                </div>
                <div className="col-12 mt-3">
                    <span>ИП Ефремова Анна Александровна</span>
                </div>
                <div className="col-12 mt-1">
                    <span>ИНН 332807852011</span>
                </div>
                <div className="col-12 mt-1">
                    <span>ОГРН 323710000008582</span>
                </div>
                <div className="col-12 mt-1">
                    <span>119526 г. Москва ул. 26 Бакинских Комиссаров, 8с2 вывеска магазин «ХОЗТОВАРЫ» или «АВТОЗАПЧАСТИ»</span>
                </div>
            </section>
            {window.YandexRotorSettings.isLoaded = true}
        </main>
    );
};

export default ContactsPage;