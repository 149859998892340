import React from 'react';
import classes from "./AutoCarousel.module.css";
import {Link} from "react-router-dom";

const AutoCarousel = ({products}) => {
    return (
        <div id="carouselAutoplaying"
             className={`${classes.carousel} col-12 carousel carousel-dark slide`}
             data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active py-3">
                    <Link to={`/product/${products[0].id}`}>
                        <img src={products[0].image[0].href} alt={products[0].name} className={classes.productImg}
                             loading="lazy"/>
                    </Link>
                </div>
                {
                    products.slice(1, 5).map(
                        product =>
                            <div className="carousel-item py-3"
                                 key={`productCarousel-${product.id}`}>
                                <Link to={`/product/${product.id}`}>
                                    <img src={product.image[0].href} alt={product.name}
                                         className={classes.productImg} loading="lazy"/>
                                </Link>
                            </div>
                    )
                }
                <button className="carousel-control-prev" type="button"
                        data-bs-target="#carouselAutoplaying"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button"
                        data-bs-target="#carouselAutoplaying"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default AutoCarousel;