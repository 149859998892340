import {auth, autoAuth, logout, sendAuthCode} from "../../shared/services/AuthService";
import {
    showAuthErrorToast,
    showAuthFormRequiredToast,
    showCodeSentSuccessfulToast,
    showLogoutErrorToast
} from "../../shared/libs/Toasts";

export const sendAuthCodeCallback = (email, setIsFetching, setIsCodeSent, alertContext, setAlertContext) => {
    const email_regex = /^[A-Za-z0-9][A-Za-z0-9.\-_]*[A-Za-z0-9]*@([A-Za-z0-9]+([A-Za-z0-9-]*[A-Za-z0-9]+)*\.)+[A-Za-z]*$/;
    if (email_regex.test(email))
        sendAuthCode(email, setIsFetching).then(
            data => {
                if (data) {
                    setIsCodeSent(true);
                    showCodeSentSuccessfulToast(alertContext, setAlertContext);
                }
            }
        );
    else showAuthFormRequiredToast(alertContext, setAlertContext);
};

export const authCallback = (email, otp, setAuthContext, setIsFetching, navigate, alertContext, setAlertContext) => {
    auth(email, otp, setAuthContext, setIsFetching).then(
        data =>
            data ?
                navigate("/profile")
                : showAuthErrorToast(alertContext, setAlertContext)
    );
};

export const autoAuthCallback = (setAuthContext, setIsFetching) => {
    autoAuth(setAuthContext, setIsFetching).then();
};

export const logoutCallback = (setAuthContext, setIsFetching, navigate, alertContext, setAlertContext) => {
    logout(setAuthContext, setIsFetching).then(
        data =>
            data ?
                navigate("/")
                : showLogoutErrorToast(alertContext, setAlertContext)
    );
};