import React from 'react';

const Toast = ({alertContext}) => {
    return (
        <div className="position-fixed bottom-0 end-0 toast align-items-center mx-2 mx-lg-3 my-2 my-lg-3"
             ref={alertContext.toast}>
            <div className="d-flex">
                <div className="toast-body">{alertContext.alert_text}</div>
                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"></button>
            </div>
        </div>
    );
};

export default Toast;