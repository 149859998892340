import React from 'react';
import classes from "./Footer.module.css";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className={classes.pageFooter}>
            <div className="container py-4">
                <div className="row">
                    <div className="col text-start my-auto">
                        <div className={classes.logo}>Мешки-Пакеты</div>
                        <div className={`${classes.address} my-1`}>г. Москва, ул. 26 Бакинских Комиссаров, 8с2</div>
                    </div>
                    <div className="col text-end my-auto">
                        <Link to="mailto:support@meshki-pakety.ru" className={classes.contacts}>
                            support@meshki-pakety.ru
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;