import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import ProductCard from "../../entities/ProductCard/ProductCard";
import {search} from "../../shared/services/ProductsService";
import {getSearchPagination} from "../../features/Pagination/Pagination";

const SearchPage = () => {
    const [searchQuery] = useSearchParams();
    const query = searchQuery.get('query');
    const page = searchQuery.get("page") ? searchQuery.get("page") : "1";
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [paginationBtn, setPaginationBtn] = useState([]);

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        search(query, page, setProducts, setIsFetching).then(
            data => getSearchPagination(data.pages, navigate, query, setPaginationBtn)
        );
        // eslint-disable-next-line
    }, [page, searchQuery]);

    window.document.title = "Мешки-Пакеты - Поиск";

    return (
        <main className="container py-4">
            <h1>
                {`Результаты поиска по запросу: ${query}`}
            </h1>
            <div className="row row-cols-4 m-0 my-4">
                {
                    isFetching ?
                        <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                        :
                        (products.length !== 0) ?
                            <>
                                {
                                    products.map(
                                        product =>
                                            <ProductCard productId={product.id} productName={product.name}
                                                         productPrice={product.price} productImg={product.image[0].href}
                                                         key={`product-${product.article}`}/>
                                    )
                                }
                                <div className="col-12 d-flex justify-content-center my-1">
                                    <div className="col-6 text-center">{paginationBtn.map(btn => btn)}</div>
                                </div>
                            </>
                            :
                            <div className="col-12 p-0">
                                <h1>Товары отсутствуют!</h1>
                            </div>
                }
            </div>
        </main>
    );
};

export default SearchPage;