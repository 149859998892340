import {Toast} from "bootstrap";

export const showToast = (alertText, alertContext, setAlertContext) => {
    setAlertContext({...alertContext, alert_text: alertText});
    const toastBootstrap = Toast.getOrCreateInstance(alertContext.toast.current);
    toastBootstrap.show();
};

export const showCreateOrderFormRequiredToast = (alertContext, setAlertContext) => {
    const showCreateOrderFormRequiredText = "Нужно заполнить обязательные поля!";
    showToast(showCreateOrderFormRequiredText, alertContext, setAlertContext);
};

export const showCreateOrderSuccessfulToast = (alertContext, setAlertContext) => {
    const showCreateOrderSuccessfulText = "Заказ успешно создан!";
    showToast(showCreateOrderSuccessfulText, alertContext, setAlertContext);
};

export const showCreateOrderErrorToast = (alertContext, setAlertContext) => {
    const showCreateOrderErrorText = "Произошла ошибка при создании заказа!";
    showToast(showCreateOrderErrorText, alertContext, setAlertContext);
};

export const showCreateOrderMessageToast = (message, alertContext, setAlertContext) => {
    showToast(message, alertContext, setAlertContext);
};

export const showCodeSentSuccessfulToast = (alertContext, setAlertContext) => {
    const showCodeSentSuccessfulText = "Код отправлен на ваш EMAIL!";
    showToast(showCodeSentSuccessfulText, alertContext, setAlertContext);
};

export const showAuthFormRequiredToast = (alertContext, setAlertContext) => {
    const showAuthFormRequiredText = "Необходимо заполнить все поля!";
    showToast(showAuthFormRequiredText, alertContext, setAlertContext);
};

export const showAuthErrorToast = (alertContext, setAlertContext) => {
    const showAuthErrorText = "Произошла ошибка при авторизации!";
    showToast(showAuthErrorText, alertContext, setAlertContext);
};

export const showLogoutErrorToast = (alertContext, setAlertContext) => {
    const showLogoutErrorText = "Произошла ошибка при выходе!";
    showToast(showLogoutErrorText, alertContext, setAlertContext);
};

export const showCancelOrderSuccessfulToast = (alertContext, setAlertContext) => {
    const showCancelOrderSuccessfulText = "Заказ успешно отменен/отправлен на возврат!";
    showToast(showCancelOrderSuccessfulText, alertContext, setAlertContext);
};

export const showCancelOrderErrorToast = (alertContext, setAlertContext) => {
    const showCancelOrderErrorText = "Произошла ошибка при отмене заказа!";
    showToast(showCancelOrderErrorText, alertContext, setAlertContext);
};