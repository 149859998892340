import React from 'react';
import classes from "./Carousel.module.css";

const Carousel = ({productInfo}) => {
    return (
        <div id="carousel" className="carousel carousel-dark slide">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={productInfo.image[0].href} alt={productInfo.name} className={classes.productImg}
                         loading="lazy"/>
                </div>
                {
                    [...productInfo.image.slice(1)].map(
                        elem =>
                            <div className="carousel-item" key={`productImg-${elem.id}`}>
                                <img src={elem.href} className={classes.productImg} alt={productInfo.name}
                                     loading="lazy"/>
                            </div>
                    )
                }
                <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default Carousel;