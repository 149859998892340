import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ProductCard from "../../entities/ProductCard/ProductCard";
import {CategoriesContext} from "../../app/contexts/CategoriesContext";
import {getProductByCategoryId} from "../../shared/services/ProductsService";
import {getPagination} from "../../features/Pagination/Pagination";

const CategoryPage = () => {
    const {categoryId} = useParams();
    const [searchQuery] = useSearchParams();
    const page = searchQuery.get("page") ? searchQuery.get("page") : "1";
    const navigate = useNavigate();

    const {categoriesContext} = useContext(CategoriesContext);

    const [category, setCategory] = useState({});
    const [products, setProducts] = useState([]);
    const [paginationBtn, setPaginationBtn] = useState([]);

    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        categoriesContext.forEach(
            category =>
                (category.id === Number(categoryId)) ?
                    setCategory(category)
                    : null
        );
        getProductByCategoryId(categoryId, page, setProducts, setIsFetching).then(
            data => getPagination(data.pages, navigate, setPaginationBtn)
        );
        // eslint-disable-next-line
    }, [page, categoryId, searchQuery, categoriesContext]);

    window.document.title = `Мешки-Пакеты - ${category.name}`;

    return (
        <main className="container my-4">
            {
                isFetching
                    ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    (products.length !== 0) ?
                        <>
                            <header>
                                <h1 className="mb-3">{category.name}</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Главная</Link></li>
                                    <li className="breadcrumb-item active">{category.name}</li>
                                </ol>
                            </header>
                            <section className="row row-cols-4 m-0 justify-content-center">
                                {
                                    products.map(
                                        product =>
                                            <ProductCard productId={product.id} productName={product.name}
                                                         productPrice={product.price}
                                                         productImg={product.image[0].href}
                                                         key={`product-${product.article}`}/>
                                    )
                                }
                            </section>
                            <footer className="col-12 d-flex justify-content-center my-1">
                                <div className="col-6 text-center">
                                    {paginationBtn.map(btn => btn)}
                                </div>
                            </footer>
                            {window.YandexRotorSettings.isLoaded = true}
                        </>
                        :
                        <section className="col-12 p-0">
                            <h1>Товары отсутствуют!</h1>
                            {window.YandexRotorSettings.isLoaded = true}
                        </section>
            }
        </main>
    );
};

export default CategoryPage;