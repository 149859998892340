import axios from "axios";

export const instance = axios.create({
    baseURL: 'https://meshki-pakety.ru/',
    withCredentials: true
});

instance.interceptors.request.use(
    config => {
        config.headers['deviceId'] = localStorage.getItem('deviceId');
        return config;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);