import React from 'react';
import classes from "./OrderItem.module.css";
import {Link} from "react-router-dom";
import {statuses} from "../../shared/services/OrdersService";

const OrderItem = ({order}) => {
    return (
        <Link to={`/track-order/${order.id}`}
              className={`${classes.orderLink} col-12 col-lg-5 border border-1 mx-3 my-2 p-2`}>
            <span className="d-block">{`Заказ #${order.id} - ${statuses[order.status]}`}</span>
            <div>
                {`${order.type === "PICKUP" ? "САМОВЫВОЗ" : "ДОСТАВКА"} (${order.payment === "CASH" ? "НАЛИЧНЫЕ" : "ОНЛАЙН"})`}
            </div>
        </Link>
    );
};

export default OrderItem;