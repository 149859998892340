import React from 'react';
import classes from "./RequisitesPage.module.css";

const RequisitesPage = () => {
    return (
        <main className="container py-4">
            <h1 className="mb-3">Реквизиты</h1>
            <section>
                <div className={`${classes.pageText} mt-1`}>Банк: ПАО СБЕРБАНК</div>
                <div className={`${classes.pageText} mt-1`}>Р/счет: 40802810038000295629</div>
                <div className={`${classes.pageText} mt-1`}>Кор/счет: 30101810400000000225</div>
                <div className={`${classes.pageText} mt-1`}>ИНН банка: 7707083893</div>
                <div className={`${classes.pageText} mt-1`}>БИК банка: 044525225</div>
                <div className={`${classes.pageText} mt-1`}>Юр. адрес банка: 117312 г. Москва, ул. Вавилова д. 19</div>
            </section>
            {window.YandexRotorSettings.isLoaded = true}
        </main>
    );
};

export default RequisitesPage;