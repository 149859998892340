import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../app/contexts/AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {logoutCallback} from "../../features/Auth/Auth";
import {AlertContext} from "../../app/contexts/AlertContext";
import {getOrders} from "../../shared/services/OrdersService";
import OrderItem from "../../entities/OrderItem/OrderItem";

const ProfilePage = () => {
    const [searchQuery] = useSearchParams();
    const page = searchQuery.get("page") ? searchQuery.get("page") : "1";
    const navigate = useNavigate();
    const {authContext, setAuthContext} = useContext(AuthContext);
    const {alertContext, setAlertContext} = useContext(AlertContext);

    const [orders, setOrders] = useState([]);

    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        if (!authContext.isAuth) navigate("/auth");
        else getOrders(setOrders, page, setIsFetching).then();
        // eslint-disable-next-line
    }, [authContext]);

    return (
        <main className="container py-4">
            <h1 className="mb-3">Личный кабинет</h1>
            <div className="row">
                <section className="col-3">
                    <div className="border border-5 p-3">
                        <div className="col-12 mb-3 d-flex justify-content-center">{authContext.email}</div>
                        <button className="col-12 btn"
                                onClick={
                                    () => logoutCallback(setAuthContext, setIsFetching, navigate, alertContext,
                                        setAlertContext)
                                }>
                            Выйти
                        </button>
                    </div>
                </section>
                <section className="col-7">
                    {
                        isFetching ?
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border">
                                    <span className="visually-hidden">Загрузки...</span>
                                </div>
                            </div>
                            :
                            (orders.length !== 0) ?
                                <div className="row">
                                    {
                                        orders.map(
                                            order => <OrderItem order={order} key={`order-${order.id}`}/>
                                        )
                                    }
                                </div>
                                :
                                <div className="d-flex justify-content-center">
                                    <h1>Нет заказов!</h1>
                                </div>
                    }
                </section>
            </div>
        </main>
    );
};

export default ProfilePage;