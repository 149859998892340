import React, {useEffect, useState} from 'react';
import {getCartPrice, getCartProducts} from "../../shared/services/ProductsService";
import {useNavigate} from "react-router-dom";
import CheckoutDetailsForm from "../../widgets/Checkout/CheckoutDetailsForm/CheckoutDetailsForm";
import CheckoutSidebar from "../../widgets/Checkout/CheckoutSidebar/CheckoutSidebar";

const CheckoutPage = () => {
    const navigate = useNavigate();

    const [cart, setCart] = useState([]);
    const [cartProducts, setCartProducts] = useState([]);
    const [cartPrice, setCartPrice] = useState(NaN);

    const [isFetching, setIsFetching] = useState(true);
    const [isBtnFetching, setIsBtnFetching] = useState(false);
    const [isPriceFetching, setIsPriceFetching] = useState(true);

    const [customerData, setCustomerData] = useState({
        phone: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        city: "",
        address: "",
        postIndex: "",
        details: ""
    });
    const [deliveryType, setDeliveryType] = useState("");
    const [paymentType, setPaymentType] = useState("");

    useEffect(() => {
        const cartInfo = localStorage.getItem('cart');
        if (cartInfo) {
            const cartObj = JSON.parse(cartInfo);
            if (cartObj.length !== 0) {
                setCart(cartObj);

                const productsId = cartObj.map(obj => obj.id);
                getCartProducts(productsId, setCartProducts, setIsFetching).then(
                    data =>
                        data ?
                            getCartPrice(cartObj, setCartPrice, setIsPriceFetching).then(
                                data => (data.wrongProducts.length !== 0) ? navigate('../cart') : null
                            )
                            : null
                );
            } else navigate('/');
        }
        // eslint-disable-next-line
    }, []);

    window.document.title = "Мешки-Пакеты - Оформление заказа";

    return (
        <main className="container py-4">
            {
                isFetching ?
                    <div className="spinner-border"><span className="visually-hidden">Загрузка...</span></div>
                    :
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            <h1 className="mb-3">ОФОРМЛЕНИЕ ЗАКАЗА</h1>
                            <CheckoutDetailsForm customerData={customerData} setCustomerData={setCustomerData}
                                                 deliveryType={deliveryType} setDeliveryType={setDeliveryType}
                                                 paymentType={paymentType} setPaymentType={setPaymentType}/>
                        </div>
                        <div className="col-12 col-lg-5">
                            <CheckoutSidebar deliveryType={deliveryType} setIsBtnFetching={setIsBtnFetching}
                                             isBtnFetching={isBtnFetching} customerData={customerData}
                                             paymentType={paymentType} cartProducts={cartProducts} cart={cart}
                                             cartPrice={cartPrice} isPriceFetching={isPriceFetching}
                                             navigate={navigate}/>
                        </div>
                    </div>
            }
        </main>
    );
};

export default CheckoutPage;