import React from 'react';
import classes from "./Header.module.css";
import {Link} from "react-router-dom";
import Navbar from "./Navbar/Navbar";

const Header = () => {
    return (
        <header className={classes.pageHeader}>
            <div className="container py-4">
                <div className="row align-items-center">
                    <Link className={`${classes.logo} col text-center text-lg-start`} to="/">Мешки-Пакеты</Link>
                    <div className={`${classes.contacts} col d-none d-lg-flex justify-content-lg-center`}>
                        <Link to="tel:+79032381725">
                            <i className="bi-telephone"></i>&nbsp;
                            <span>+7 903 238 1725</span>
                        </Link>
                    </div>
                    <div className={`${classes.work_time} col text-end d-none d-lg-flex justify-content-lg-end`}>
                        Время работы: 9:00 до 18:00
                    </div>
                </div>
            </div>
            <Navbar/>
        </header>
    );
};

export default Header;