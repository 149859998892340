import {BaseService} from "./BaseService";
import {sendAuthCodeRequest, sendAuthRequest, sendAutoAuthRequest, sendLogoutRequest} from "../api/Auth";

export const sendAuthCode = (email, setIsFetching) => {
    const reqFunc = () => sendAuthCodeRequest(email);
    const okFunc = () => true;
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const auth = (email, otp, setAuthContext, setIsFetching) => {
    const reqFunc = () => sendAuthRequest(email, otp);
    const okFunc = data => {
        setAuthContext({isAuth: true, email: data.email});
        localStorage.setItem("isAuth", "true");
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const autoAuth = (setAuthContext, setIsFetching) => {
    const reqFunc = sendAutoAuthRequest;
    const okFunc = data => {
        setAuthContext({isAuth: true, email: data.email});
        localStorage.setItem("isAuth", "true");
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};

export const logout = (setAuthContext, setIsFetching) => {
    const reqFunc = sendLogoutRequest;
    const okFunc = () => {
        setAuthContext({isAuth: false, email: ""});
        localStorage.setItem("isAuth", "false");
        return true;
    };
    return BaseService(reqFunc, okFunc, setIsFetching);
};