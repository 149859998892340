import React from 'react';

import classes from "./ProductDetails.module.css";

const ProductDetails = ({productId, productInfo}) => {
    return (
        <>
            <h2 className={classes.productDetailTitle}>Параметры</h2>
            {
                productInfo.detail.map(
                    detail =>
                        <div
                            className={`${classes.productDetail} d-flex justify-content-between py-3`}
                            key={`productDetail-${detail.id}`}>
                            <span className={classes.detailName}>{detail.name}</span>
                            <span className={classes.detailValue}>{detail.value}</span>
                        </div>
                )
            }
            <div className={`${classes.productDetail} d-flex justify-content-between py-3`} key={`weight-${productId}`}>
                <span className={classes.detailName}>Вес</span>
                <span className={classes.detailValue}>{productInfo.weight}</span>
            </div>
        </>
    );
};

export default ProductDetails;