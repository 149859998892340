import React from 'react';
import classes from "./CartSidebar.module.css";
import {Link} from "react-router-dom";

const CartSidebar = ({isPriceFetching, cartPrice, wrongProductsExists}) => {
    return (
        <div className={`${classes.cartSidebar} p-4 col-12`}>
            <h3 className="mb-4">СУММА ЗАКАЗА</h3>
            <div className="d-flex justify-content-between mb-4">
                <span className={classes.totalText}>Итого</span>
                <span className={classes.totalPrice}>
                    {
                        isPriceFetching ?
                            <div className="spinner-border spinner-border-sm mx-2">
                                <span className="visually-hidden">Загрузка...</span>
                            </div>
                            : `${cartPrice} ₽`
                    }
                </span>
            </div>
            <div className="d-flex justify-content-center">
                {
                    wrongProductsExists ?
                        <button className="col-12 btn" disabled>Оформить заказ</button>
                        :
                        <Link className="col-12 btn" to="../checkout">
                            Оформить заказ
                        </Link>
                }
            </div>
        </div>
    );
};

export default CartSidebar;