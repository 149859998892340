import React from 'react';
import {statuses} from "../../../shared/services/OrdersService";
import classes from "./CustomerData.module.css";

const CustomerData = ({status, pin, customerData}) => {
    return (
        <>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <div>Статус заказа</div>
                <div className={`${classes.totalPrice} ms-3 text-break`}>{statuses[status]}</div>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <div>PIN</div>
                <div className="ms-3 text-break">{pin}</div>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <div>Телефон</div>
                <div className="ms-3 text-break">{customerData.phone}</div>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Email</span>
                <span className="ms-3 text-break">{customerData.email}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Имя</span>
                <span className="ms-3 text-break">{customerData.firstName}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Фамилия</span>
                <span className="ms-3 text-break">{customerData.lastName}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Отчество</span>
                <span className="ms-3 text-break">{customerData.middleName}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Компания</span>
                <span className="ms-3 text-break">{customerData.company}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Город</span>
                <span className="ms-3 text-break">{customerData.city}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Адрес</span>
                <span className="ms-3 text-break">{customerData.address}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Индекс</span>
                <span className="ms-3 text-break">{customerData.postIndex}</span>
            </div>
            <div
                className="col-12 d-flex justify-content-between align-items-center py-3 mb-1 border-bottom border-2">
                <span>Комментарий</span>
                <span className="ms-3 text-break">{customerData.details}</span>
            </div>
        </>
    );
};

export default CustomerData;