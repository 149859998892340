import React from 'react';
import {Link} from "react-router-dom";
import classes from "./ProductCard.module.css";

const ProductCard = ({productId, productName, productPrice, productImg}) => {
    return (
        <article className={`${classes.productCard} col row mx-lg-4 p-3 m-3 text-center`}>
            <header className="col-12 p-0">
                <Link to={`/product/${productId}`}>
                    <img src={productImg} alt="" className="img-fluid" width="300px" height="300px" loading="lazy"/>
                </Link>
            </header>
            <span className="col-12 p-0 mt-2">
                <Link to={`/product/${productId}`} className={classes.productName}>{productName}</Link>
            </span>
            <footer className="col-12 p-0 d-flex justify-content-between align-items-end mt-3">
                <span className={classes.productPrice}>{`${productPrice} ₽`}</span>
                <Link className={`${classes.buyBtn} btn`} to={`../product/${productId}`}>КУПИТЬ</Link>
            </footer>
        </article>
    );
};

export default ProductCard;