import React from 'react';

const DeliveryInputs = ({customerData, setCustomerData}) => {
    return (
        <>
            <div className="col-12 mb-3">
                <label htmlFor="lastName" className="form-label">Фамилия</label>
                <input type="text" className="form-control" id="lastName" value={customerData.lastName}
                       onChange={
                           event => setCustomerData({...customerData, lastName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="firstName" className="form-label">Имя</label>
                <input type="text" className="form-control" id="firstName" value={customerData.firstName}
                       onChange={
                           event => setCustomerData({...customerData, firstName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="middleName" className="form-label">Отчество</label>
                <input type="text" className="form-control" id="middleName" value={customerData.middleName}
                       onChange={
                           event => setCustomerData({...customerData, middleName: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="companyName" className="form-label">
                    Название компании (необязательно)
                </label>
                <input type="text" className="form-control" id="companyName" value={customerData.company}
                       onChange={
                           event => setCustomerData({...customerData, company: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="city" className="form-label">Город</label>
                <input type="text" className="form-control" id="city" value={customerData.city}
                       onChange={
                           event => setCustomerData({...customerData, city: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="address" className="form-label">Адрес</label>
                <input type="text" className="form-control" id="address" value={customerData.address}
                       onChange={
                           event => setCustomerData({...customerData, address: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="postIndex" className="form-label">Индекс</label>
                <input type="text" className="form-control" id="postIndex" value={customerData.postIndex}
                       onChange={
                           event => setCustomerData({...customerData, postIndex: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="phone" className="form-label">Телефон</label>
                <input type="tel" className="form-control" id="phone" maxLength="11"
                       placeholder="В формате 79998887766" value={customerData.phone}
                       onChange={
                           event => {
                               const changedValue = event.target.value;
                               const isValidNumber = !isNaN(Number(changedValue)) &&
                                   (changedValue[0] === "7" || changedValue === "");
                               if (isValidNumber) setCustomerData({
                                   ...customerData,
                                   phone: changedValue.replaceAll('.', '')
                               });
                           }
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="email" className="form-label">Email (на него придет информация о
                    заказе)</label>
                <input type="email" className="form-control" id="email" value={customerData.email}
                       onChange={
                           event => setCustomerData({...customerData, email: event.target.value})
                       }/>
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="details" className="form-label">Комментарий к заказу (необязательно)</label>
                <textarea className="form-control" id="details" value={customerData.details}
                          onChange={
                              event => setCustomerData({...customerData, details: event.target.value})
                          }/>
            </div>
        </>
    );
};

export default DeliveryInputs;