import React from 'react';
import classes from "./Pagination.module.css";

const Pagination = ({page, navigate}) => {
    return (
        <button className={`${classes.paginationBtn} btn m-2`} key={`paginationBtn-${page}`}
                onClick={() => navigate(`?page=${page}`)}>
            {page}
        </button>
    );
};

export default Pagination;