import {sendTokenRefreshRequest} from "../api/Auth";

export const BaseService = async (reqFunc, okFunc, setIsFetching) => {
    setIsFetching(true);
    return new Promise(
        resolve =>
            reqFunc().then(
                response => {
                    if (response.status === 200) {
                        const result = okFunc(response.data);
                        setIsFetching(false);
                        resolve(result);
                    }
                },
                responseReject => {
                    if (responseReject.response.status === 401) {
                        sendTokenRefreshRequest().then(
                            () =>
                                reqFunc().then(
                                    response => {
                                        const result = okFunc(response.data);
                                        setIsFetching(false);
                                        resolve(result);
                                    }
                                )
                        ).catch(
                            () => {
                                setIsFetching(false);
                                resolve(false);
                            }
                        );
                    } else {
                        setIsFetching(false);
                        resolve(false);
                    }
                }
            ).catch(
                error => {
                    setIsFetching(false);
                    return error;
                }
            )
    );
};