import React, {useState} from 'react';
import classes from "./SearchForm.module.css";
import {useNavigate} from "react-router-dom";

const SearchForm = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState("");

    return (
        <div className="col-12 col-lg-8">
            <form className="input-group" onSubmit={event => {
                event.preventDefault();
                navigate(`../search-products?query=${query}`);
            }}>
                <input type="search" className="form-control py-2 px-4" placeholder="Поиск" value={query}
                       onChange={event => setQuery(event.target.value)}/>
                <button className={`${classes.search_btn} btn py-2 px-3`} type="submit">Найти</button>
            </form>
        </div>
    );
};

export default SearchForm;