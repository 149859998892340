import {createOrder} from "../../shared/services/OrdersService";
import {
    showCreateOrderErrorToast,
    showCreateOrderFormRequiredToast,
    showCreateOrderMessageToast,
    showCreateOrderSuccessfulToast
} from "../../shared/libs/Toasts";

export const createOrderCallback = (deliveryType, customerData, paymentType, cart, setIsBtnFetching, alertContext,
                                    setAlertContext, navigate) => {
    const email_regex = /^[A-Za-z0-9][A-Za-z0-9.\-_]*[A-Za-z0-9]*@([A-Za-z0-9]+([A-Za-z0-9-]*[A-Za-z0-9]+)*\.)+[A-Za-z]*$/;
    if (deliveryType === "PICKUP") {
        if ((customerData.phone.length !== 0) && (customerData.email.length !== 0) &&
            (email_regex.test(customerData.email)) && (paymentType.length !== 0)) {
            const orderObj = {
                customerData: {
                    phone: customerData.phone,
                    email: customerData.email,
                    details: customerData.details,
                    firstName: customerData.firstName,
                    middleName: customerData.middleName,
                    lastName: customerData.lastName,
                    company: "", city: "", address: "", postIndex: ""
                },
                type: deliveryType,
                products: [...cart],
                payment: paymentType
            };
            createOrder(orderObj, setIsBtnFetching).then(
                data => {
                    if (data) {
                        showCreateOrderSuccessfulToast(alertContext, setAlertContext);
                        localStorage.setItem('cart', '[]');
                        if (data.confirmationUrl) window.location.href = data.confirmationUrl;
                        else navigate("/");
                    } else showCreateOrderErrorToast(alertContext, setAlertContext);
                }
            );
        } else showCreateOrderFormRequiredToast(alertContext, setAlertContext);
    } else if (deliveryType === 'DELIVERY') {
        if ((customerData.phone.length !== 0) && (customerData.email.length !== 0) &&
            (customerData.firstName.length !== 0) && (customerData.middleName.length !== 0) &&
            (customerData.lastName.length !== 0) && (customerData.city.length !== 0) &&
            (customerData.address.length !== 0) && (customerData.postIndex.length !== 0) &&
            (email_regex.test(customerData.email)) && (deliveryType.length !== 0)) {
            const orderObj = {
                customerData: {...customerData},
                type: deliveryType,
                products: [...cart],
                payment: "ONLINE"
            };
            createOrder(orderObj, setIsBtnFetching).then(
                data => {
                    if (data) {
                        if (data.confirmationUrl) {
                            showCreateOrderSuccessfulToast(alertContext, setAlertContext);
                            localStorage.setItem('cart', '[]');
                            window.location.href = data.confirmationUrl;
                        } else showCreateOrderMessageToast(data.message, alertContext, setAlertContext);
                    } else showCreateOrderErrorToast(alertContext, setAlertContext);
                }
            );
        } else showCreateOrderFormRequiredToast(alertContext, setAlertContext);
    }
};