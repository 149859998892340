import {getCartProductsRequest} from "../../shared/api/Products";
import {getCartPrice} from "../../shared/services/ProductsService";

export const changeProductCountCallback = (inputValue, product, cart, setCart, setTotalInfo, setCartPrice,
                                           setIsPriceFetching, setWrongProductsExists) => {
    // Проверка на количество товара >=1
    const changedNum = Number(inputValue);
    if (1 <= changedNum && changedNum <= 9999) {
        const cartProductIndex = cart.findIndex(obj => obj.id === product.id);
        // Проверка на присутствие товара в корзине
        if (cartProductIndex !== -1) {
            // Изменение количество товара в корзине
            const newCart = [...cart];
            newCart[cartProductIndex].amount = changedNum;
            setCart(newCart);
            localStorage.setItem('cart', JSON.stringify(newCart));

            const products = newCart.map(obj => obj.id);
            updateTotalInfo(products, newCart, setTotalInfo);

            getCartPrice(newCart, setCartPrice, setIsPriceFetching).then(
                data =>
                    (data.wrongProducts.length !== 0) ?
                        setWrongProductsExists(true)
                        : setWrongProductsExists(false)
            );
        }
    }
};

export const addProductToCart = (cart, numProductId, productAmount, setCart, setInCart) => {
    const cartProductIndex = cart.findIndex(obj => obj.id === numProductId);
    if (cartProductIndex === -1) {
        const newCart = [...cart];
        newCart.splice(-1, 0, {
                id: numProductId,
                amount: productAmount
            }
        );
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
        setInCart(true);
    }
};

export const removeProductCallback = (index, product, setTotalInfo, cart, setCart, cartProducts, setCartProducts,
                                      setCartPrice, setIsPriceFetching, setWrongProductsExists) => {
    // Проверка на присутствие товара в корзине
    const cartProductIndex = cart.findIndex(obj => obj.id === product.id);
    if (cartProductIndex !== -1) {
        // Удаление товара из корзины
        const newCart = [...cart];
        newCart.splice(cartProductIndex, 1);
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));

        // Удаление товара из продуктов корзины
        const newCartProducts = [...cartProducts];
        newCartProducts.splice(index, 1);
        setCartProducts(newCartProducts);

        // Если в корзине есть товары...
        if (newCart.length !== 0) {
            const products = newCart.map(obj => obj.id);
            updateTotalInfo(products, newCart, setTotalInfo);
        }
        // Обновление итоговой суммы корзины
        getCartPrice(newCart, setCartPrice, setIsPriceFetching).then(
            data =>
                (data.wrongProducts.length !== 0) ?
                    setWrongProductsExists(true)
                    : setWrongProductsExists(false)
        );
    }
};

export const updateTotalInfo = (products, newCart, setTotalInfo) => {
    // Обновление итога
    getCartProductsRequest(products).then(
        data => {
            if (data.data) {
                let totalWeight = 0;
                let totalAmount = 0;
                let totalPrice = 0;
                data.data.forEach(
                    product => {
                        totalWeight += product.weight * newCart.find(elem => elem.id === product.id).amount;
                        totalAmount += newCart.find(elem => elem.id === product.id).amount;
                        totalPrice += product.price * newCart.find(elem => elem.id === product.id).amount;
                    }
                )
                setTotalInfo({totalWeight, totalAmount, totalPrice})
            }
        }
    );
};