import {instance} from "./Base";

export const getProductsByCategoryIdRequest = async (categoryId, page) => {
    return await instance.get("/get-products-by-categoryId", {
        params: {categoryId, page}
    });
};

export const getProductRequest = async productId => {
    return await instance.get("/product", {
        params: {productId}
    });
};

export const getCartProductsRequest = async products => {
    return await instance.post("/products", {products});
};

export const getCartPriceRequest = async cart => {
    return await instance.post("/get-cart-price", {cart});
};

export const searchRequest = async (query, page) => {
    return await instance.get("/search", {
        params: {query, page}
    });
};

export const randomProductsRequest = async count => {
    return await instance.get("/random-products", {
        params: {count}
    });
};